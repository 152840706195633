import { useIsAuthenticating } from "@core/hooks/useIsAuthenticating";
import { getUser } from "@core/hooks/useUser";
import Alert from "@core/ui/Alert";
import { last, noop } from "lodash/fp";
import { useRouter } from "next/router";
import { getSession } from "next-auth/react";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";

import { CheckEmailStatuses } from "../services/nocd-api";
import CheckEmailForm from "./CheckEmailForm";
import ForgotPasswordConfirmation from "./ForgotPasswordConfirmation";
import LoginForm from "./LoginForm";
import NewPasswordForm from "./NewPasswordForm";
import OneTimeCodeForm from "./OneTimeCodeForm";
import RegistrationForm from "./RegistrationForm";

export type AuthenticationFormVariants = "conqueror" | "default" | "community";

const VARIANT_TO_SOURCE_MAP: Record<AuthenticationFormVariants, string> = {
  conqueror: "member_portal_conqueror",
  community: "member_portal_community",
  default: "member_portal",
};

const VARIANT_TO_GA_EVENT_MAP: Record<AuthenticationFormVariants, string> = {
  default: "76893c63",
  conqueror: "239506e3",
  community: "44a8b3d1",
};

const getHeading = (
  variant: AuthenticationFormVariants,
  overrideHeading?: string
) => {
  if (overrideHeading) {
    return overrideHeading;
  }

  switch (variant) {
    case "conqueror":
      return "Welcome Back";
    case "community":
      return "Join the world's largest OCD community";
    default:
      return "Get started with NOCD";
  }
};

const getSubHeading = (variant: AuthenticationFormVariants) => {
  switch (variant) {
    case "conqueror":
      return "Sign in to share your story of becoming an OCD Conqueror.";
    case "community":
      return "Start by creating a free account";
    default:
      return "Enter your email address below.";
  }
};

enum AuthenticationFormScreens {
  CHECK_EMAIL,
  LOGIN_WELCOME,
  LOGIN_FORGOT_PASSWORD_CONFIRMATION,
  REGISTER_WELCOME,
  REGISTER_EXISTING_ACCOUNT_ONE_TIME_CODE,
  REGISTER_EXISTING_ACCOUNT_NEW_PASSWORD,
}

interface AuthenticationFormProps {
  /**
   * A fuction called after the user successfully logs in or registers. This
   * is called before the user is redirected to the next page. In practice,
   * you'll probably only use this if the user is authenticating through a
   * modal and you want to close it.
   */
  onSuccess?: () => Promise<unknown>;
  /**
   * By default, new users and existing users will be redirected to another
   * page after authenticating. Using this prop, you can toggle that behavior
   * on and off. This prop is particularly useful when the user performs an
   * action that requires them to be logged in. Using this prop, you can make
   * sure the user stays on the same page.
   */
  redirectAfterAuthentication?: boolean;
  variant?: AuthenticationFormVariants;
}

const areCookiesDisabled = () =>
  typeof navigator !== "undefined" && !navigator.cookieEnabled;

interface State {
  email: string;
  oneTimeCode: string;
  stack: AuthenticationFormScreens[];
}

export function AuthenticationForm({
  redirectAfterAuthentication = true,
  onSuccess = () => Promise.resolve(noop),
  variant = "default",
}: AuthenticationFormProps): JSX.Element {
  const router = useRouter();
  const { presetEmail, loginHeading } = router.query;

  // have to do a tiny bit of trickery here in case the email has a `+` character
  // `+` gets encoded in URLs as a space (for some reason)
  // You can safely replace spaces with `+` for the purposes of this "auto fill the email"
  const normalizedPresetEmail =
    typeof presetEmail === "string" ? presetEmail.replaceAll(" ", "+") : null;

  const [state, setState] = useState<State>({
    email: normalizedPresetEmail ?? null,
    oneTimeCode: null,
    stack: normalizedPresetEmail
      ? [
          AuthenticationFormScreens.CHECK_EMAIL,
          AuthenticationFormScreens.LOGIN_WELCOME,
        ]
      : [AuthenticationFormScreens.CHECK_EMAIL],
  });

  const { email, oneTimeCode, stack } = state;
  const currentScreen = last(stack);

  const back = useCallback(
    () =>
      setState((previousState) => ({
        ...previousState,
        stack:
          previousState.stack.length > 1
            ? previousState.stack.slice(0, -1)
            : previousState.stack,
      })),
    []
  );

  const push = useCallback(
    (
      screen: AuthenticationFormScreens,
      newState?: Partial<Omit<State, "stack">>
    ) =>
      setState((previousState) => ({
        ...previousState,
        ...newState,
        stack: previousState.stack.concat(screen),
      })),
    []
  );

  const queryClient = useQueryClient();
  const [_, setIsLoading] = useIsAuthenticating();

  const getNextUrl = (
    hasCompletedCommunityOnboarding: boolean,
    isTherapyMember: boolean,
    hasUpcomingIntakeCall: boolean,
    hasLinkedAccounts: boolean,
    isRootAccount: boolean
  ): string => {
    const { query, asPath } = router;
    const { redirectTo } = query;
    // See if the URL has a `redirectTo` query param we can use.
    const normalizedRedirectTo = Array.isArray(redirectTo)
      ? redirectTo[0]
      : redirectTo;

    let nextUrl =
      typeof normalizedRedirectTo === "string" &&
      normalizedRedirectTo.startsWith("/")
        ? normalizedRedirectTo
        : undefined;

    if (!nextUrl) {
      nextUrl =
        isTherapyMember || hasUpcomingIntakeCall ? "/therapy" : "/community";
    }

    // To lower barrier to entry, we skip the onboarding screen if the user
    // is a therapy member or has an upcoming intake call.
    if (
      !isTherapyMember &&
      !hasUpcomingIntakeCall &&
      !hasCompletedCommunityOnboarding
    ) {
      nextUrl = `/onboarding?redirectTo=${asPath}`;
    }

    if (hasLinkedAccounts && isRootAccount) {
      if (normalizedPresetEmail) {
        // coming in from an account switch context, skip the profile picker
        nextUrl = `/therapy`;
      } else {
        nextUrl = `/profiles`;

        if (
          typeof normalizedRedirectTo === "string" &&
          normalizedRedirectTo.startsWith("/")
        ) {
          nextUrl += `?r=${normalizedRedirectTo}`;
        }
      }
    }

    return nextUrl;
  };

  const handleLoginSuccess = async () => {
    setIsLoading(true);
    const session = await getSession();
    const user = await getUser(session?.accessToken as string);
    await queryClient.cancelQueries();
    await queryClient.invalidateQueries();

    // Primary use case: log in from a modal shown after performing an action
    // that requires the user to be logged in.
    if (!redirectAfterAuthentication) {
      return onSuccess().then(() => setIsLoading(false));
    }

    const nextUrl = getNextUrl(
      user?.hasCompletedCommunityOnboarding,
      user?.isTherapyMember,
      user?.hasUpcomingIntakeCall,
      user?.linkedAccounts?.length > 0,
      user?.isRootAccount
    );

    return onSuccess()
      .then(() => router.replace(nextUrl))
      .then(() => setIsLoading(false));
  };

  return (
    <>
      {areCookiesDisabled() && (
        <Alert
          className="mb-4"
          severity="error"
          message="To log in, please enable cookies in your browser."
        />
      )}

      {currentScreen === AuthenticationFormScreens.CHECK_EMAIL && (
        <CheckEmailForm
          heading={getHeading(variant, loginHeading as string)}
          subHeading={getSubHeading(variant)}
          onSuccess={({ email: checkedEmail, status }) => {
            switch (status) {
              case CheckEmailStatuses.NEEDS_PASSWORD_RESET:
                return push(
                  AuthenticationFormScreens.REGISTER_EXISTING_ACCOUNT_ONE_TIME_CODE,
                  { email: checkedEmail }
                );
              case CheckEmailStatuses.NEEDS_LOGIN:
                return push(AuthenticationFormScreens.LOGIN_WELCOME, {
                  email: checkedEmail,
                });
              case CheckEmailStatuses.NEEDS_REGISTER:
                return push(AuthenticationFormScreens.REGISTER_WELCOME, {
                  email: checkedEmail,
                });
              default:
                return undefined;
            }
          }}
        />
      )}

      {currentScreen === AuthenticationFormScreens.LOGIN_WELCOME && (
        <LoginForm
          onClickBack={back}
          email={email}
          onSuccess={handleLoginSuccess}
          onClickForgotPassword={() =>
            push(AuthenticationFormScreens.LOGIN_FORGOT_PASSWORD_CONFIRMATION)
          }
        />
      )}

      {currentScreen ===
        AuthenticationFormScreens.LOGIN_FORGOT_PASSWORD_CONFIRMATION && (
        <ForgotPasswordConfirmation email={email} goBackToLoginScreen={back} />
      )}

      {currentScreen === AuthenticationFormScreens.REGISTER_WELCOME && (
        <RegistrationForm
          email={email}
          onSuccess={handleLoginSuccess}
          onClickBack={back}
          source={VARIANT_TO_SOURCE_MAP?.[variant]}
          eventToRecord={VARIANT_TO_GA_EVENT_MAP?.[variant]}
        />
      )}

      {currentScreen ===
        AuthenticationFormScreens.REGISTER_EXISTING_ACCOUNT_ONE_TIME_CODE && (
        <OneTimeCodeForm
          title="Great news!"
          description="We already have an account created for you! Check your email for the verification code we just sent you."
          email={email}
          onSuccess={(newOneTimeCode: string) => {
            return push(
              AuthenticationFormScreens.REGISTER_EXISTING_ACCOUNT_NEW_PASSWORD,
              { oneTimeCode: newOneTimeCode }
            );
          }}
        />
      )}

      {currentScreen ===
        AuthenticationFormScreens.REGISTER_EXISTING_ACCOUNT_NEW_PASSWORD && (
        <NewPasswordForm
          onClickBack={back}
          onSuccess={handleLoginSuccess}
          email={email}
          oneTimeCode={oneTimeCode}
          eventToRecord={VARIANT_TO_GA_EVENT_MAP?.[variant]}
        />
      )}
    </>
  );
}
